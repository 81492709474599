














































































import { Component, Vue } from 'vue-property-decorator';
import { userNamespace } from '@store/modules/user';
import { sidebarNamespace } from "@store/modules/sidebar";
import { UserActionTypes } from '@store/modules/user/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import './scss/BaseHeader.scss';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { SidebarMutationTypes } from '@store/modules/sidebar/Types';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { UserProjectsMutations } from '@store/modules/user-projects/Types';

const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');
const NSUserProject = namespace('storeUserProjects');

@Component({
    name: 'BaseHeader',

    components: {
        PopupHelp: () => import('@components/popups/PopupHelp.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        HeaderSearch: () => import('@components/BaseHeader/HeaderSearch.vue'),
        AccountActions: () => import('@components/BaseHeader/AccountActions.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        PopupChangePassword: () => import('@components/popups/PopupChangePassword.vue'),
    },
})
export default class BaseHeader extends Vue {
    $refs!: {
        popupHelp: HTMLFormElement,
        popupChangePassword: HTMLFormElement,
    }

    @userNamespace.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectData') projectData!: IProject;
    @userNamespace.Action(UserActionTypes.A_LOGOUT) logout!: () => Promise<void>;
    @sidebarNamespace.Getter('sidebarShow') sidebarShow!: boolean;
    @sidebarNamespace.Mutation(SidebarMutationTypes.M_SET_SIDEBAR_SHOW) setSidebarShow!: (payload: boolean) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_SET_PROJECT_DOCUMENTS) setDocumentsInStore!: (docs: IDocument[]) => Promise<void>
    @NSUserProject.Mutation(UserProjectsMutations.M_SET_USER_PROJECTS) setProjectsInStore!: (projects: IProject[]) => void;

    popupNotificationOpen: boolean = false;
    popupNotificationTitle: string = '';
    popupNotificationText: string = '';
    popupNotificationOpenDuration: number = 3500;

    userActionsShow: boolean = false;

    get userAuth() {
        return this.userData && this.userData.isAuthUser;
    }

    get showEngineerPartButton() {
        if (this.$route.params && this.$route.params.projectId) {
            return this.userData.userInfo && this.userData.userInfo.engineerPart;
        }
        return false;
    }

    get projectId() {
        if (this.$route.params.projectId) {
            return this.$route.params.projectId;
        }
        return null;
    }

    get projectSelected() {
        return this.projectData && this.projectData.id;
    }

    makeLogout() {
        this.logout().then(() => {
            this.setProjectsInStore([]);
            this.setDocumentsInStore([]);
            this.setSidebarShow(false);
            if (this.$route.name !== 'auth') {
                this.$router.push({ path: '/auth' });
            }
        });
    }

    onSuccessSendFeedback() {
        this.$emit('confirm-delete-task');
        this.showNotificationPopup(
            'Сообщение отправлено',
            'Сообщение успешно отправлено. Мы свяжимся с вами в ближайшее время.');
    }

    openHelpPopup() {
        this.$refs.popupHelp.openPopup();
    }

    openPopupChangePassword() {
        this.$refs.popupChangePassword.openPopup();
    }

    showNotificationPopup(title = '', text = '') {
        this.popupNotificationTitle = title;
        this.popupNotificationText = text;
        this.popupNotificationOpen = true;
        setTimeout(() => {
            this.popupNotificationOpen = false;
            this.popupNotificationTitle = '';
            this.popupNotificationText = '';
        }, this.popupNotificationOpenDuration);
    }
}
